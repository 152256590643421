import React from 'react';
import './contest.css'

const VideoPopup = ({ video, onClose }) => {
  if (!video) return null;

  return (
    <div className="custom-popup-overlay" onClick={onClose}>
      <div className="custom-popup-content" onClick={e => e.stopPropagation()}>
        <iframe
          width="100%"
          height="400"
          src={
            video.youtube_path.includes("drive.google.com")
              ? video.youtube_path.replace(/(\/file\/d\/[^/]+)\/.*/, "$1/preview")
              : video.youtube_path
          }
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={video.name}
        ></iframe>
        <div className="custom-popup-description">
          <h3>{video.name}</h3>
          <p>{video.descrpt}</p>
        </div>
        <button className="custom-close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default VideoPopup;
