import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './profile.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import img1 from '../../image/account_circle_24dp_FILL0_wght400_GRAD0_opsz24 1.png'
import img2 from '../../image/school_24dp_FILL0_wght400_GRAD0_opsz24 1.png'
import img3 from '../../image/trophy_24dp_FILL0_wght400_GRAD0_opsz24 1.png'
import back_img from '../../image/Night-Light-Painting-Photography-Tips-2-1024x683.png'
import no_profile from '../../image/no_profile.jpeg'
import Modal from './modal';
import ShareButton from '../share_button/share_button';
import Modal1 from '../Timeline/model';
import pimg from '../../image/pimg1.png';
import pvid from '../../image/pvid.png';
import edit_img from '../../image/edit.svg'
import mask from '../../image/footer-logo-2 2.svg'
import profile_logo from '../../image/Vector.png'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



import QRCode from "react-qr-code";
import { jsPDF } from 'jspdf';
import MembershipCard from '../../image/MembershipCard.png';



function Profile() {
  const location = useLocation();
  const [filter, setFilter] = useState('images');
  const [images, setImages] = useState([]);
  const [videos, setvideos] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const [fullImageSrc, setFullImageSrc] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [youtubeVideos, setYoutubeVideos] = useState([]);
  const [Name, setName] = useState('');
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [followersList, setFollowersList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowing, setShowFollowing] = useState(false);
  const [image, setImage] = useState('');
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const unique_id = localStorage.getItem('uniqueId');
  const userName = localStorage.getItem('name');
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [aboutUs, setAboutUs] = useState('');
  const [college, setCollege] = useState('');
  const [achievements, setAchievements] = useState('');
  const [role, setRole] = useState('');
  const [profileImageURL, setProfileImageURL] = useState(null);
  const [coverImageURL, setCoverImageURL] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [profileImage1, setProfileImage1] = useState(null);
  const [coverImage1, setCoverImage1] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [renderimg, setRenderimg] = useState(false);
  const [loading,setLoading]=useState(false);
  const navigate = useNavigate();
  
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);


  



  const [userEmail, setUserEmail] = useState(null);
  const [membershipTabActive, setMembershipTabActive] = useState(false);

  const canvasRef = useRef(null);
  const qrRef = useRef(null);

  const waitForCanvas = (ref) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (ref.current) {
          clearInterval(interval);
          resolve(ref.current);
        }
      }, 50); // Check every 50ms
    });
  };
  
  const drawCanvas = async () => {
    const canvas = await waitForCanvas(canvasRef);
    const context = canvas.getContext('2d');

    // Load the background image
    const background = new Image();
    background.src = MembershipCard; // Set the path to your uploaded image

    background.onload = async() => {
        // Draw the background image
        context.drawImage(background, 0, 0, canvas.width, canvas.height);

        // Capitalize the Name
        const capitalizedName = Name.toUpperCase();

        // Draw Name
        context.font = '44px Arial';
        context.fillStyle = 'white';
        // Calculate the x-coordinate to center the text
        const nameWidth = context.measureText(capitalizedName).width;
        const nameXPosition = (canvas.width - nameWidth) / 2;

        context.fillText(capitalizedName, nameXPosition, 655);

        // Draw ID Number
        context.font = '28px Arial';
        const idWidth = context.measureText(unique_id).width;
        const idXPosition = (canvas.width - idWidth) / 2;
        context.fillText(`${unique_id}`, idXPosition, 800); // Adjust coordinates

         // Convert the QR code SVG to an image
         const svgElement = qrRef.current;
        if (svgElement) {
            const svgData = new XMLSerializer().serializeToString(svgElement);
            const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
            const svgUrl = URL.createObjectURL(svgBlob);

            const qrImage = new Image();
            qrImage.src = svgUrl;
            qrImage.onload = () => {
                const qrSize = 150; // Use the native size of the SVG for best clarity
                const qrX = (canvas.width - qrSize) / 2; // Center horizontally
                context.drawImage(qrImage, qrX, 825, qrSize, qrSize);
                URL.revokeObjectURL(svgUrl); // Clean up
            };
        }
    };
  };

  const downloadPDF = () => {
    const canvas = canvasRef.current;
    const pdf = new jsPDF('portrait', 'px', [canvas.width, canvas.height]);
    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save(`${Name}_MembershipCard.pdf`);
  };








  useEffect(() => {
    // Check local storage for authentication state
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
    if (authStatus != 'true') {
      navigate('/')
    }

    //check if membership form is filled or not, if not filled, navigate to membership form
    (async () => {
      try {
        const response = await axios.get(`https://backend.clicktalksnow.com/get_is_membership_form_filled/${unique_id}`);
        if (response) {
          if(!response.data.is_membership_form_filled){
            navigate('/membership-form')
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    })();
  }, []);


  useEffect(() => {
    //fetch user email using unique_id
    (async () => {
      try {
        const response = await axios.get(`https://backend.clicktalksnow.com/get_user_email/${unique_id}`);
        if (response) {
          setUserEmail(response.data.email)
        }
      } catch (error) {
        console.error('Error in fetching user email:', error);
      }
    })();
  }, []);

  const toggleSearchResultsSidebar = () => {
    setSearchResultsSidebarOpen(!isSearchResultsSidebarOpen);
  };
  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Disable common keyboard shortcuts for opening developer tools
    const handleKeyDown = (e) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
      }
    };

    // Detect if developer tools are open and take action
    const handleDevToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, 'id', {
        get: function () {
          alert('Developer tools are open!');
          window.location.replace('about:blank'); // Redirect to a blank page
        },
      });
      console.log(element);
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    handleDevToolsOpen();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      fetch('  https://backend.clicktalksnow.com/search_bar/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ search_text: query })
      })
        .then(response => response.json())
        .then(data => {

          setSearchResults(data.Names);
          setSearchResultsSidebarOpen(data.Names.length > 0); // Assuming the API directly returns the list of names

        })
        .catch(error => console.error('Error fetching search results:', error));
    } else {
      setSearchResults([]);
      setSearchResultsSidebarOpen(false); // Clear results if the query is cleared
    }
  };


  const handleOpenModal1 = () => {
    setShowModal1(true);
    
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };


  // Fetch initial data from the API
  const fetchuserdetails = async () => {
    axios.get(`  https://backend.clicktalksnow.com/get_user_details/${unique_id}`)
      .then(response => {

        const { profile_image, cover_image, about_you, clg, acheivements, role } = response.data.result[0];

        console.log("PROFILE IMAGE: ", profile_image);
        console.log("COVER IMAGE: ", cover_image);

        setProfileImage1(profile_image);
        setCoverImage1(cover_image);
        setAboutUs(about_you);
        setCollege(clg);
        setAchievements(acheivements);
        setRole(role);
        fetch(`${profile_image}`)
          .then(response => response.blob())
          .then(blob => {
            const filename = profile_image.split('/')[-1]
            const file = new File([blob], filename, { type: blob.type });
            const objectURL = URL.createObjectURL(file);
            setProfileImage(file)
            setProfileImageURL(objectURL)
          });

        fetch(`${cover_image}`)
          .then(response => response.blob())
          .then(blob => {
            const filename = cover_image.split('/')[-1]
            const file = new File([blob], filename, { type: blob.type });
            const objectURL = URL.createObjectURL(file);

            setCoverImage(file)
            setCoverImageURL(objectURL)
          });
      })
      .catch(error => {
        alert('Error fetching the profile data:', error);
      });
  }


  const toggleSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen);
    if (!isMobileSidebarOpen) {
      setMobileNavbarOpen(false); // Close navbar if sidebar is opened
    }
  };

  const toggleNavbar = () => {
    setMobileNavbarOpen(!isMobileNavbarOpen);
    if (!isMobileNavbarOpen) {
      setMobileSidebarOpen(false); // Close sidebar if navbar is opened
    }
  };


  useEffect(() => {
    if (filter === 'images') {
      fetchImages();
    }
    if (filter === 'videos') {
      fetchVideos();
    }
    if (filter === 'youtube') {
      fetchYoutube();
    }

  }, [filter]);

  useEffect(() => {
    fetchName();

    fetchuserdetails();
  }, [])

  useEffect(() => {
    fetchImages();
    fetchVideos();
    fetchYoutube();
  }, [renderimg])


  const fetchName = async () => {
    try {
      const response = await axios.get(`  https://backend.clicktalksnow.com/get_id/${unique_id}`);
      if (response) {
        const fetchName = response.data.Name;

        setName(fetchName)
      }
    } catch (error) {
      console.error('Error fetching unique_id:', error);
    }
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get(`  https://backend.clicktalksnow.com/images/${unique_id}`);
      setImages(response.data.image_paths);
      
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  const fetchVideos = async () => {
    try {
      const response = await axios.get(`  https://backend.clicktalksnow.com/videos/${unique_id}`);
      setvideos(response.data.videos)
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchYoutube = async () => {
    try {
      const response = await axios.get(`  https://backend.clicktalksnow.com/get_youtube_video/${unique_id}`);

      setYoutubeVideos(response.data.result)
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    axios.get(`  https://backend.clicktalksnow.com/followers_count/${unique_id}`)
      .then(response => {
        setFollowersCount(response.data.followers_count);
      })
      .catch(error => console.error('Error fetching followers count:', error));

    axios.get(`  https://backend.clicktalksnow.com/following_count/${unique_id}`)
      .then(response => {
        setFollowingCount(response.data.following_count);
      })
      .catch(error => console.error('Error fetching following count:', error));
  }, []);


  const fetchFollowersList = async () => {
    try {
      const response = await axios.get(`  https://backend.clicktalksnow.com/followers/${unique_id}`);
      setFollowersList(response.data.followers);

    } catch (error) {
      console.error('Error fetching followers list:', error);
    }
  };

  const fetchFollowingList = async () => {
    try {
      const response = await axios.get(`  https://backend.clicktalksnow.com/following/${unique_id}`);
      setFollowingList(response.data.following);


    } catch (error) {
      console.error('Error fetching following list:', error);
    }
  };


  const handleShowFollowers = () => {
    fetchFollowersList();
    setShowFollowers(true);
  };

  const handleShowFollowing = () => {
    fetchFollowingList();
    setShowFollowing(true);
  };

  const handleCloseModal = () => {
    setShowFollowers(false);
    setShowFollowing(false);
  };

  const handleImageChange = (e, setImage, setImageURL) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageURL(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); 
    const formData = new FormData();
    if (profileImage) formData.append('profile_image', profileImage);
    if (coverImage) formData.append('cover_image', coverImage);
    formData.append('role', role);
    formData.append('about_you', aboutUs);
    formData.append('clg', college);
    formData.append('achievements', achievements);

    axios.put(`  https://backend.clicktalksnow.com/update_user_details/${unique_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {

        setShowModal(false);
        fetchuserdetails();

      })
      .catch(error => {
        console.error('Error updating the profile:', error);
      })
      .finally(() => {
        setLoading(false); // End loading state
    });
      
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };









  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleUploadImage = async () => {
  //   if (!selectedFile) return;

  //   const formData = new FormData();
  //   formData.append('file', selectedFile);

  //   try {
  //     const response = await axios.post(`  https://backend.clicktalksnow.com/upload_image/${unique_id}/${userName}`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });

  //     fetchImages();  // Refresh images list
  //   } catch (error) {
  //     console.error('Error uploading image:', error);
  //   }
  // };

  // const handleUploadvideo = async () => {
  //   if (!selectedFile) return;

  //   const formData = new FormData();
  //   formData.append('file', selectedFile);

  //   try {
  //     await axios.post(`  https://backend.clicktalksnow.com/upload_video/${unique_id}/${userName}`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });
  //     fetchVideos();  // Refresh images list
  //   } catch (error) {
  //     console.error('Error uploading image:', error);
  //   }
  // };






  const handleYoutubeSubmit = async () => {
    try {
      const response = await fetch(`  https://backend.clicktalksnow.com/upload/youtube_video/${userName}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ unique_id: unique_id, youtube_path: youtubeUrl }),
      });
      const data = await response.json();
      fetchYoutube()

      setYoutubeUrl('');
    } catch (error) {
      console.error('Error:', error);
    }
  }



  const handleDelete = async (filter, id) => {
    try {
      // const unique_id = localStorage.getItem('uniqueId');
      const endpoint = filter === 'image' ? `delete_image/${unique_id}/${id}/` : `delete_video/${unique_id}/${id}/`;
      const response = await axios.delete(`  https://backend.clicktalksnow.com/${endpoint}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to delete file');
      }


      filter === 'image' ? fetchImages() : fetchVideos();
    } catch (error) {
      console.error('Error deleting file:', error.message);
    }
  };


  const handleDelete1 = async (type, id) => {
    try {
      // Make a DELETE request to delete the YouTube video
      const response = await fetch(`  https://backend.clicktalksnow.com/delete_youtube/${unique_id}/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchYoutube()

      } else {
        console.error(`Failed to delete YouTube video with ID ${id}.`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const showImage = (src) => {
    setFullImageSrc(src);
    setShowFullImage(true);
  };

  const render = (value) => {
    setRenderimg(value)
  }


  const handleSignOut = () => {
    // Clear local storage
    localStorage.clear();

    // Redirect to the homepage or login page

    navigate('/');
  };

  const closeImage = () => {
    setShowFullImage(false);
  };


  return (
    <div className="profile_sid123">
      <div className="profile-page">
        <div className="navbar">
          {/* <div className="navbar-menu-icon" onClick={toggleSidebar}>
        &#9776; 
          </div> */}
          <div className="navbar-logo1">
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>
          <div className='searching'>
            {isAuthenticated && (
               <div className="search-bar">
               <FontAwesomeIcon icon={faSearch} className="search-icon" />
               <input
                 type="text"
                 placeholder="Search"
                 className="search-input"
                 value={searchQuery}
                 onChange={handleSearch}
               />
             </div>)}
          </div>
          <div className="navbar-links">

            <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>


            <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

            {isAuthenticated && userName ? (
              <>
                <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                <Link to="/" onClick={handleSignOut}>Sign Out</Link>
              </>
            ) : (
              <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
            )}


          </div>
          <div className="navbar-menu" onClick={toggleNavbar}>
            &#9776;
          </div>
          {isMobileNavbarOpen && (
            <div className="mobile-navbar-links">
              <Link to='/'>Home</Link>

              <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>


              <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
              <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

              {isAuthenticated && userName ? (
                <>
                  <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                  <Link to="/" onClick={handleSignOut}>Sign Out</Link>
                </>
              ) : (
                <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
              )}


            </div>
          )}
        </div>

        <div
          className="banner"
          style={{ backgroundImage: `url(${coverImageURL})` }}

        ></div>
        <div className="content">
          <div className="profile-card">

            <img src={`${profileImage1}`} alt="Profile" className="profile-pic" />
            <div className='name_align'>
              <h2>{Name}</h2>
              <p className='img_edit' onClick={openModal}><img src={edit_img}></img></p>
            </div>
            <h5>{role}</h5>
            <div className='Line-profile'></div>
            <ul className="about">
              <li>
                <span>{followersCount}</span>
                <button className='but123' onClick={handleShowFollowers}>Followers</button>
              </li>
              <li>
                <span>{followingCount}</span>
                <button className='but123' onClick={handleShowFollowing}>Following</button>
              </li>
            </ul>

            <div className='image_upload_timeline1'>
              <button className="upload-button1" onClick={handleOpenModal1} >+ New Post</button>
            </div>

            <Modal1 show={showModal1} handleClose={handleCloseModal1} sr={render} />


            <p><span><img src={img1}></img></span>{aboutUs}</p>
            <p><span><img src={img2}></img></span>{college}</p>
            <p><span><img src={img3}></img></span>Achievements</p>
            <ul className='ul_profile'>
              <li>{achievements}</li>
            </ul>

            {/* <div className='edit_profile_btn'>
              <button onClick={openModal}>Edit Profile</button>
            </div> */}
          </div>
          <div className="posts-section">

            <div className="buttons">
              <div style={{display:'flex', flexWrap:'wrap', gap:20, marginBottom:20}}>
                <button style={{ fontWeight: '600' }} onClick={()=>setMembershipTabActive(false)}>My Posts</button>
                <button style={{ fontWeight: '600' }} onClick={()=>{setMembershipTabActive(true); drawCanvas();}}>Membership Card</button>
              </div>
              {
                !membershipTabActive &&
                <div className='buttons-2' style={{margin:0}}>
                  <div className='p-img-btn' onClick={() => setFilter('images')}>
                    <span><img src={pimg}></img></span>
                    <button

                      className={`filter-button ${filter === 'images' ? 'active' : ''}`}
                      style={{ fontWeight: '600' }}
                    >
                      Images
                    </button>
                  </div>
                  <div className='p-img-btn' onClick={() => setFilter('videos')}>
                    <span><img src={pvid}></img></span>
                    <button
                      className={`filter-button ${filter === 'videos' ? 'active' : ''}`}
                      style={{ fontWeight: '600' }}
                    >
                      Videos
                    </button>
                  </div>
                {/* <div className='p-img-btn' onClick={() => setFilter('youtube')}>
                  <button
                    className={`filter-button ${filter === 'youtube' ? 'active' : ''}`}
                    style={{ fontWeight: '600' }}
                  >
                    YouTube Videos
                  </button>
                </div> */}
              </div>
              }
            </div>


            {/* {filter === 'youtube' && (
              <div className="upload-section">
                <input
                  type="text"
                  placeholder="Paste YouTube embed URL"
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                />
                <button onClick={handleYoutubeSubmit}>Upload YouTube Video</button>
              </div>
            )} */}
            {
              !membershipTabActive &&
              <div className="posts">
              {filter === 'images' && images.map((src, i) => (
                <div key={i} className="post1" >
                  <img className='post123' src={`${src.image_paths}`} alt={src.image_paths} onClick={() => showImage(src.image_paths)} />

                  <div className="likes">
                    <div>
                      <span>Likes : </span>
                      <span>{src.likes}</span>

                    </div>
                    <div style={{
                      width: '24px',cursor:'pointer'
                    }}><ShareButton url={src.image_paths} title={Name} /></div>
                  </div>
                  <div className="card-overlay">
                    <button onClick={() => handleDelete('image', src.id)}>Delete</button>
                  </div>
                </div>
              ))}
              {filter === 'videos' && videos.map((src, i) => (
                <div key={i} className="post1" >
                 <iframe src={src.video_path} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen className="post-video"></iframe>

                  <div className="likes">
                    <div>
                      <span>Likes:</span>
                      <span>{src.likes}</span>

                    </div>
                    <div style={{cursor:'pointer'}}><ShareButton url={src.video_path} title={Name} /></div>
                  </div>
                  <div className="card-overlay">
                    <button onClick={() => handleDelete('videos', src.id)}>Delete</button>
                  </div>
                </div>
              ))}
              {filter === 'youtube' && youtubeVideos.map((video, i) => (
                <div key={i} className="post1">
                  <iframe
                    width="100%"
                    height="200"
                    src={video.youtube_path}
                    title={`YouTube video ${i}`}

                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>

                  <div className="likes">
                    <div>
                      <span>Likes:</span>
                      <span>{video.likes}</span>

                    </div>
                    <div style={{cursor:'pointer'}}><ShareButton url={video.youtube_path} title={Name} /></div>
                  </div>
                  <div className="card-overlay">
                    <button onClick={() => handleDelete1('youtube', video.id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
            }

            {
              userEmail &&
              /* Hidden QR Code */
              <div style={{ display:"none", maxWidth: 150 }}>
                <QRCode ref={qrRef} 
                  style={{ height: "auto", maxWidth: "100%", width: "100%"}}
                  value={`https://membershipdetails.netlify.app/?email=${userEmail}`}
                />
              </div>
            }
            {
              membershipTabActive &&
              userEmail && (
                <div style={{display:'flex', flexWrap:'wrap', gap:10}}>
                  {/* Canvas */}
                  <canvas ref={canvasRef} width={590} height={1004}/>
                  <button style={{alignSelf:"start"}} onClick={downloadPDF}>Download as PDF</button>
                </div>
              )
            }
          </div>
        </div>
        {showFullImage && (
          <div className="full-image-modal">
            <img src={`${fullImageSrc}`} alt="Full View" />
            <button className="close-button" onClick={closeImage}>×</button>
          </div>
        )}
      </div>
      {showFollowers && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Followers</h2>
            <ul>
              {followersList.map((follower, index) => (
                <li key={index}>{follower.name}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {showFollowing && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Following</h2>
            <ul>
              {followingList.map((following, index) => (
                <li key={index}>{following.name}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {isSearchResultsSidebarOpen && (
        <div className="search-results-sidebar">
          <button onClick={toggleSearchResultsSidebar}>Close</button>
          {searchResults.map(result => (
            <div className="search-result">
              <Link to={`/userprofile?name=${result}`}>
                <img src={profile_logo} alt="Profile Logo" className="profile-logo" />
                <span className="profile-name">{result}</span>
              </Link>
            </div>
          ))}
        </div>
      )}
      {showModal && (
        <Modal onClose={closeModal}>
          <form onSubmit={handleSubmit} className="edit-profile-form">
            <div className="form-group">
              <label htmlFor="profileImage">Profile Image:</label>
              <input
                type="file"
                id="profileImage"
                accept="image/*"
                onChange={(e) => handleImageChange(e, setProfileImage, setProfileImageURL)}
              />
              {profileImageURL && <img src={profileImageURL} alt="Profile" className="preview-image" />}
            </div>

            <div className="form-group">
              <label htmlFor="coverImage">Cover Image:</label>
              <input
                type="file"
                id="coverImage"
                accept="image/*"
                onChange={(e) => handleImageChange(e, setCoverImage, setCoverImageURL)}
              />
              {coverImageURL && <img src={coverImageURL} alt="Cover" className="preview-image" />}
            </div>

            <div className="form-group">
              <label htmlFor="aboutUs">About Us:</label>
              <textarea
                id="aboutUs"
                value={aboutUs}
                onChange={(e) => setAboutUs(e.target.value)}
                className="text-area"
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="college">College:</label>
              <input
                type="text"
                id="college"
                value={college}
                onChange={(e) => setCollege(e.target.value)}
                className="text-input"
              />
            </div>

            <div className="form-group">
              <label htmlFor="achievements">Achievements:</label>
              <textarea
                id="achievements"
                value={achievements}
                onChange={(e) => setAchievements(e.target.value)}
                className="text-area"
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="role">Role:</label>
              <input
                type="text"
                id="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="text-input"
              />
            </div>

            <button type="submit" className="submit-button" disabled={loading}>
                {loading ? 'Saving...' : 'Save'}
            </button>
          </form>
        </Modal>
      )}

    </div>
  );
}

export default Profile;

