import React  from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Myprofile from './componenets/profile/profile'
import Register from './componenets/register/register'
import MembershipForm from './componenets/membership/membership'
import Login from './componenets/Login/login'
import Timeline from "./componenets/Timeline/timeline";
import Test from "./componenets/user_profile_2/user_profile";
import About from './componenets/About/about'
import Home from './componenets/Home/home'
import Contest from "./componenets/Contest/contest";
import Share from './componenets/Post/post'



function App(){
  return (
    <Router>
       <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/membership-form" element={<MembershipForm />} />
        <Route path="/login" element={<Login/>} /> 
        <Route path="/myprofile" element={<Myprofile />} />
        <Route path="/timeline" element={<Timeline/>}/>
        <Route path="/userprofile" element={<Test/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/share" element={<Share/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/Contest" element={<Contest/>}/>
      </Routes>
   </Router>
  
  
  )
}

export default App;