import React, { useState, useEffect, useRef } from 'react';
import './home.css';
import img1 from './image/clicktalks design _page-0002.png';
import img2 from './image/clicktalks design _page-0005.png';
import img3 from './image/Red Professional Fashion YouTube Thumbnail_page-0002 (1).png';
import img4 from './image/Red Professional Fashion YouTube Thumbnail_page-0006 (1).png';
import img5 from './image/Red Professional Fashion YouTube Thumbnail_page-0008 (1).png';
import img6 from './image/Red Professional Fashion YouTube Thumbnail_page-0009 (1).jpg';
import img7 from './image/Red Professional Fashion YouTube Thumbnail_page-0010 (1).webp';
import img8 from './image/Red Professional Fashion YouTube Thumbnail_page-0011 (1).jpg';
import img_layer from '../../image/Layer 1.png'
import mask from '../../image/footer-logo-2 2.svg'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';



const Home = () => {
  const location = useLocation();
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authStatus = localStorage.getItem('isAuthenticated');
  const userName = localStorage.getItem('name');

  useEffect(() => {
    // Check local storage for authentication state

    setIsAuthenticated(authStatus === 'true');
  }, [authStatus]);

  const toggleSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen);
    if (!isMobileSidebarOpen) {
      setMobileNavbarOpen(false); // Close navbar if sidebar is opened
    }
  };

  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Disable common keyboard shortcuts for opening developer tools
    const handleKeyDown = (e) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
      }
    };

    // Detect if developer tools are open and take action
    const handleDevToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, 'id', {
        get: function () {
          alert('Developer tools are open!');
          window.location.replace('about:blank'); // Redirect to a blank page
        },
      });
      console.log(element);
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    handleDevToolsOpen();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const toggleNavbar = () => {
    setMobileNavbarOpen(!isMobileNavbarOpen);
    if (!isMobileNavbarOpen) {
      setMobileSidebarOpen(false); // Close sidebar if navbar is opened
    }
  };
  const [sliderItems] = useState([
    {
      src: img4, title: 'FILMMAKERS ZONE',
      topic: 'Embrace the art of Film making - Aspiring Directors.',
      titile2: '',
      top: '25%',
      styles: {
        fontsize1: '8em',
        fontsize2: '1.8em',
        transform: 'translateX(-50%)',
        fontfamily1: 'League Spartan, sans-serif',
        fontfamily2: 'Montserrat, sans-serif',
        color1: '#E8B349',
        color2: 'white',
        btn_col1: '#0A0B0D',
        btn_back: '#E8B349',
        btn_col2: '#E8B349',
        dashed: '2px dashed #E8B349',
        fontsizemob: '5em'
      }
    },
    {
      src: img1, title: 'THEATRE',
      topic: 'A Vibrant Haven for theatre artists and aspiring performers',
      top: '10%',
      titile2: 'LOVERS',
      styles: {
        fontsize1: '8em',
        fontsize2: '2em',
        transform: 'translateX(-50%)',
        fontfamily1: 'Volkhov, serif',
        fontfamily2: 'Montserrat, sans-serif',
        color1: 'white',
        bold1: 'bold',
        color2: 'white',
        btn_col1: '#141414',
        btn_back: '#FFFFFF',
        btn_col2: '#141414',
        dashed: '2px dashed #141414',
        fontsizemob: '5em'
      }
    },
    {
      src: img5, title: "DANCE MANIA'S ZONE",
      topic: 'A Thrilling Hub for Dance Enthusiasts of all styles & expressions',
      top: '28%',
      titile2: '',
      styles: {
        fontsize1: '7em',
        fontsize2: '1.8em',
        transform: 'translateX(-50%)',
        fontfamily1: 'League Spartan, sans-serif',
        fontfamily2: 'Montserrat, sans-serif',
        color1: 'white',
        color2: 'white',
        btn_col1: '#1B2527',
        btn_back: '#FFFFFF',
        btn_col2: '#FFFFFF',
        dashed: '2px dashed #FFFFFF',
        fontsizemob: '4em'
      }
    },
    {
      src: img3, title: 'FASHION',
      topic: 'Uniting Style, Passion and creativity',
      top: '10%',
      titile2: 'CONNECTS',
      styles: {
        fontsize1: '8em',
        fontsize2: '2em',
        transform: 'translateX(-50%)',
        fontfamily1: 'Chonburi, sans-serif',
        fontfamily2: 'Montserrat, sans-serif',
        color1: 'white',
        color2: 'white',
        btn_col1: '#B11723',
        btn_back: '#FFFFFF',
        btn_col2: '#FFFFFF',
        dashed: '2px dashed #FFFFFF',
        fontsizemob: '5em'
      }
    },
    {
      src: img2, title: 'GAMERS',
      topic: 'Unlock Your Potential in Game Development!',
      top: '20%',
      titile2: 'DREAMLAND',
      styles: {
        fontsize1: '6em',
        fontsize2: '1.8em',
        transform: 'translateX(-50%)',
        fontfamily1: 'Press Start 2P',
        fontfamily2: 'Montserrat, sans-serif',
        color1: 'black',
        color2: 'black',
        btn_col1: '#141414',
        btn_back: '#E8B349',
        btn_col2: '#141414',
        dashed: '2px dashed #141414',
        fontsizemob: '3.5em'
      }
    },
    {
      src: img6, title: 'PHOTOGRAPHY',
      topic: 'Capture Moments, Create Memories - For Aspiring Photographers',
      top: '22%',
      titile2: '',
      styles: {
        fontsize1: '7em',
        fontsize2: '1.8em',
        transform: 'translateX(-50%)',
        fontfamily1: 'Chonburi, sans-serif',
        fontfamily2: 'Montserrat, sans-serif',
        color1: '#FFD700',
        color2: 'white',
        btn_col1: '#333',
        btn_back: '#FFD700',
        btn_col2: '#FFD700',
        dashed: '2px dashed #FFD700',
        fontsizemob: '4.5em'
      }
    },
    {
      src: img7, title: 'STANDUP COMEDY',
      topic: 'The Art of Laughter - A Stage for Aspiring Comedians',
      top: '15%',
      titile2: '',
      styles: {
        fontsize1: '8em',
        fontsize2: '2em',
        transform: 'translateX(-50%)',
        fontfamily1: 'League Spartan, sans-serif',
        fontfamily2: 'Montserrat, sans-serif',
        color1: '#FFFFFF',
        color2: 'white',
        btn_col1: '#141414',
        btn_back: '#FF6347',
        btn_col2: '#FF6347',
        dashed: '2px dashed #FF6347',
        fontsizemob: '5em'
      }
    },
    {
      src: img8, title: 'SINGING',
      topic: 'Unleash Your Voice - A Platform for Budding Singers',
      top: '18%',
      titile2: '',
      styles: {
        fontsize1: '7.5em',
        fontsize2: '2em',
        transform: 'translateX(-50%)',
        fontfamily1: 'Press Start 2P',
        fontfamily2: 'Montserrat, sans-serif',
        color1: '#FFFFFF',
        color2: 'white',
        btn_col1: '#1B2527',
        btn_back: '#8A2BE2',
        btn_col2: '#8A2BE2',
        dashed: '2px dashed #8A2BE2',
        fontsizemob: '4.5em'
      }
    }
]);

  const carouselRef = useRef(null);
  const sliderRef = useRef(null);
  const thumbnailRef = useRef(null);
  const timeRef = useRef(null);
  const runNextAutoRef = useRef(null);

  const timeRunning = 500;
  const timeAutoNext = 7000;

  useEffect(() => {
    runNextAutoRef.current = setTimeout(() => {
      document.getElementById('next').click();
    }, timeAutoNext);

    return () => clearTimeout(runNextAutoRef.current);
  }, []);

  const showSlider = (type) => {
    const sliderItemsDom = sliderRef.current.querySelectorAll('.carousel .list .item');
    const thumbnailItemsDom = thumbnailRef.current.querySelectorAll('.carousel .thumbnail .item');

    if (type === 'next') {
      sliderRef.current.appendChild(sliderItemsDom[0]);
      thumbnailRef.current.appendChild(thumbnailItemsDom[0]);
      carouselRef.current.classList.add('next');
    } else {
      sliderRef.current.prepend(sliderItemsDom[sliderItemsDom.length - 1]);
      carouselRef.current.classList.add('prev');

      // Immediate update of thumbnails
      const firstThumbnail = thumbnailRef.current.querySelector('.item');
      const lastThumbnail = thumbnailRef.current.querySelector('.item:last-child');
      thumbnailRef.current.insertBefore(lastThumbnail, firstThumbnail);
    }

    setTimeout(() => {
      carouselRef.current.classList.remove('next');
      carouselRef.current.classList.remove('prev');
    }, timeRunning);

    clearTimeout(runNextAutoRef.current);
    runNextAutoRef.current = setTimeout(() => {
      document.getElementById('next').click();
    }, timeAutoNext);
  };
  const handleSignOut = () => {
    // Clear local storage
    localStorage.clear();

    // Redirect to the homepage or login page
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div className='Home1'>
      <div >

        <div className="navbar" style={{ zIndex: '100', fontSize: '18px' }}>
          <div className="navbar-logo1">
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>
          <div className="navbar-links">
            {isAuthenticated && (
              <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
            )}

            <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

            {isAuthenticated && userName ? (
              <>
                <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                <Link to="/" onClick={handleSignOut}>Sign Out</Link>
              </>
            ) : (
              <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
            )}



          </div>
          <div className="navbar-menu" onClick={toggleNavbar}>
            &#9776; {/* Three dots icon for navbar */}
          </div>

          {isMobileNavbarOpen && (

            <div className="mobile-navbar-links">
              <Link to='/'>Home</Link>
              {isAuthenticated && (
                <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
              )}

              <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
              <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

              {isAuthenticated && userName ? (
                <>
                  <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                  <Link to="/" onClick={handleSignOut}>Sign Out</Link>
                </>
              ) : (
                <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
              )}

            </div>
          )}
        </div>
        <div className="carousel" ref={carouselRef}>
          <div className="list" ref={sliderRef}>
            {sliderItems.map((item, index) => (
              <div className={`item ${index === 0 ? 'active' : ''}`} key={index}>

                <img src={item.src} alt={item.title} />
                {/* {item.title === 'FASHION' && (<img className="img_layer" src={img_layer} style={{zIndex:'1000'}}></img>)} */}
                <div className="content" style={{ top: `${item.top}`, width: '100%', padding: '20px' }}>
                  <div className="title" style={{
                    fontSize: isMobile ? `${item.styles.fontsizemob}` : `${item.styles.fontsize1}`, fontFamily: `${item.styles.fontfamily1}`
                    , color: `${item.styles.color1}`, display: isMobile ? '' : 'flex', justifyContent: 'end'
                  }}>{item.title}</div>
                  <div className='title' style={{
                    fontSize: isMobile ? `${item.styles.fontsizemob}` : `${item.styles.fontsize1}`, fontFamily: `${item.styles.fontfamily1}`
                    , color: `${item.styles.color1}`, display: isMobile ? '' : 'flex', justifyContent: 'end'
                  }}>{item.titile2}</div>
                  <div className="topic" style={{
                    fontSize:  `${item.styles.fontsize2}`, fontFamily: `${item.styles.fontfamily2}`,
                    color: `${item.styles.color2}`, display: 'flex', justifyContent: 'end'
                  }}>{item.topic}</div>
                  {!isAuthenticated && (<div className="buttons" >
                    <button style={{
                      borderRadius: '10px', fontFamily: 'League Spartan, sans-serif', fontWeight: 'bold', fontSize: '18px', letterSpacing: '0px',
                      backgroundColor: `${item.styles.btn_back}`
                    }}><Link to='/register' style={{ color: `${item.styles.btn_col1}` }}>Register Now</Link></button>
                    <button style={{ marginLeft: '12px', borderRadius: '10px', fontFamily: 'League Spartan, sans-serif', border: `${item.styles.dashed}`, fontSize: '18px', letterSpacing: '0px' }}>
                      <Link to='/Login' style={{ color: `${item.styles.btn_col2}` }}>Login</Link></button>
                  </div>)}
                </div>
              </div>
            ))}
          </div>
          
            <div className="thumbnail" ref={thumbnailRef} >
              {sliderItems.map((item, index) => (
                <div
                  className={`item ${index === 0 ? 'active' : ''}`}
                  key={index}
                // onClick={handleThumbnailClick}
                >
                  <img src={item.src} alt={item.title} />
                  <div className="content">
                    <div className="title">{item.title}</div>

                  </div>
                </div>
              ))}
            </div>
          <div className="arrows">
            <button id="prev" onClick={() => showSlider('prev')}>&lt;</button>
            <button id="next" onClick={() => showSlider('next')}>&gt;</button>
          </div>
          <div className="time" ref={timeRef}></div>
        </div>
      </div>
      <div className='Footer'>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="footer-col">
                <h4>Address</h4>
                <ul>
                  <li><a href="#">Rajbhavan road,</a></li>
                  <li><a href="#">Somajiguda,</a></li>
                  <li><a href="#">Hyderabad - 500082</a></li>
                </ul>
              </div>
              <div className="footer-col">
                <h4>follow us</h4>
                <div className="social-links">
                <a href="https://www.facebook.com/share/9guFd4Fb3vxLRKAR/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={30} style={{ color: '#4267B2',}} />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <FaTwitter size={30} style={{ color: '#1DA1F2', }} />
      </a>
      <a href="https://www.instagram.com/clicktalksfilmclub?igsh=MTNiN3dudjk3a2d0ZQ==" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={30} style={{ color: '#E1306C' }} />
      </a>
                </div>
              </div>
              <div className="footer-col">
                <h4>Contacts</h4>
                <ul>
                  <li><a href="#">+91 7287851628,</a></li>
                  <li><a href="#">clicktalksnow@gmail.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;
